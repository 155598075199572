export const Routes = {
  // pages
  Home: { path: "/home" },
  DashboardOverview: { path: "/dashboard/overview" },
  DashboardTraffic: { path: "/dashboard/traffic-and-engagement" },
  DashboardProductAnalysis: { path: "/dashboard/product-analysis" },
  Messages: { path: "/messages" },
  SingleMessage: { path: "/message" },
  Transactions: { path: "/transactions" },
  Tasks: { path: "/tasks" },
  Calendar: { path: "/calendar" },
  Hotspots: { path: "/hotspots" },
  Map: { path: "/map" },
  Datatables: { path: "/tables/datatables" },
  BootstrapTables: { path: "/tables/bootstrap-tables" },
  Pricing: { path: "/pricing" },
  Billing: { path: "/billing" },
  Invoice: { path: "/invoice" },
  Signin: { path: "/" },
  Signup: { path: "/sign-up" },
  ForgotPassword: { path: "/forgot-password" },
  ResetPassword: { path: "/reset-password" },
  Lock: { path: "/lock" },
  NotFound: { path: "/404" },
  Forbidden: { path: "/403" },
  ServerError: { path: "/500" },
  VerifyEmail: { path: "/verify-email" },

  // Incidents
  IncidentsTable: { path: "/tables/incidents" },
  Incidents: { path: "/incidents" },
  IncidentsList: { path: "/list/incidents" },
  Incident: { path: "/incidents/:id" },
  NewIncident: { path: "/new/incident" },
  EditIncident: { path: "/edit-incident/:id" },
  IncidentEdit: { path: "/edit-incident" },

  // Regions
  RegionsTable: { path: "/tables/regions" },
  Regions: { path: "/regions" },
  Region: { path: "/regions/:id" },
  NewRegion: { path: "/new/region" },
  EditRegion: { path: "/edit-region/:id" },
  RegionEdit: { path: "/edit-region" },

  // Reports
  ReportsTable: { path: "/tables/reports" },
  Reports: { path: "/reports" },
  Report: { path: "/reports/:id" },
  NewReport: { path: "/new/report" },
  EditReport: { path: "/edit-report/:id" },
  ReportEdit: { path: "/edit-report" },

  // Users
  UsersTable: { path: "/tables/users" },
  Users: { path: "/users" },
  User: { path: "/users/:id" },
  NewUser: { path: "/new/user" },
  EditUser: { path: "/edit-user/:id" },
  UserEdit: { path: "/edit-user" },
  Settings: { path: "/settings" },

  // Categories
  CategoriesTable: { path: "/tables/categories" },
  Categories: { path: "/categories" },
  Category: { path: "/categories/:id" },
  NewCategory: { path: "/new/category" },
  EditCategory: { path: "/edit-category/:id" },
  CategoryEdit: { path: "/edit-category" },

  // Keywords
  KeywordsTable: { path: "/tables/keywords" },
  Keywords: { path: "/keywords" },
  Keyword: { path: "/keywords/:id" },
  NewKeyword: { path: "/new/keyword" },
  EditKeyword: { path: "/edit-keyword/:id" },
  KeywordEdit: { path: "/edit-keyword" },

  // Sentiment
  Sentiment: { path: "/sentiment" },

  // Tweets
  Tweet: { path: "/tweets" },

  // Threats
  Summary: { path: "/dashboard/summary" },
  CreateSummary: { path: "/dashboard/new/summary" },
  EditSummary: { path: "/dashboard/summary/:id" },
  SingleSummary: { path: "/monitoring-report" },

  // Image framer
  CreateTemplate: { path: "/dashboard/template" },
  UpdateCampaign: { path: "/dashboard/campaigns/:id" },
  CampaignList: { path: "/dashboard/campaigns" },
  Campaigns: { path: "/campaigns" },
  CreateCampaign: { path: "/campaigns/:id" },
};
